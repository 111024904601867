import { useEffect } from 'react';
import { matchRoutes, Outlet, useLocation } from 'react-router';
import { ScrollRestoration } from 'react-router-dom';
import { Footer, GlobalToastRegion } from '@sezane/front-components';

import LoaderWrapper from '@components/Loader/LoaderWrapper';
import { useAppContext } from '@context/AppContext';
import { useAuth } from '@context/AuthContext';
import { useAnalytics } from '@hooks/useAnalytics';
import type { Routes } from '@hooks/useUrl';
import { useUrl } from '@hooks/useUrl';
import Header from './components/Header/Header';

const nestedRoutes = (routes: Routes) => [
    { path: `${routes.orders}/detail/:orderId` },
    { path: `${routes.returns}/detail/:returnId` },
];

interface LayoutProps {
    isSimplified?: boolean;
}

const Layout = ({ isSimplified }: LayoutProps) => {
    const { isAuthenticating } = useAuth();
    const { urls, routes } = useUrl();
    const location = useLocation();
    const withNestedRoutes = matchRoutes(nestedRoutes(routes), location);
    const { site, siteSegment } = useAppContext();
    const { onPageChange, interceptProductClick } = useAnalytics();

    const getBackButtonUrl = (matchedRoute: typeof withNestedRoutes) => {
        if (!!matchedRoute) {
            return matchedRoute && matchedRoute[0]?.pathname?.split('/detail')[0];
        }
        return null;
    };

    useEffect(() => {
        if (site) {
            onPageChange(site);
        }
    }, [site, location, onPageChange]);

    useEffect(() => {
        interceptProductClick();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header isSimplified={isSimplified} backButtonUrl={getBackButtonUrl(withNestedRoutes)} />
            <div className="mt-8 mobile:mt-0 mobile:pt-[70px] desktop:px-24">
                <div className="grid grid-cols-12 large:container mobile:px-6 desktop:py-6">
                    <LoaderWrapper loaderProps={{ fullScreen: true }} isLoading={isAuthenticating}>
                        <Outlet />
                    </LoaderWrapper>
                    <GlobalToastRegion toastClassName="font-body text-lg italic" />
                </div>
                <Footer url={urls.ecommerceRoot} siteCode={siteSegment as Uppercase<string>} />
            </div>
            <ScrollRestoration />
        </>
    );
};

export default Layout;
