import { formatPostCodeAndCity } from '@sezane/front-components';

import type { OMS } from 'types';

interface AddressSimpleProps {
    address: OMS.Address | OMS.OptionalPhysicalAddress;
    className?: string;
}

const AddressSimple = ({ address, className }: AddressSimpleProps) => {
    return (
        <p className={className}>
            {address.address}
            {address.addressMore && (
                <>
                    <br />
                    {address.addressMore}
                </>
            )}
            <br />
            {formatPostCodeAndCity({
                countryCode: address.countryCode,
                city: address.city,
                postcode: address.postcode,
            })}
        </p>
    );
};

export default AddressSimple;
