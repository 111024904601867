import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import AddressSimple from '@components/Address/AddressSimple';
import { PageHeader } from '@components/commons';
import LinkButton from '@components/Link/LinkButton';
import { useAppContext } from '@context/AppContext';
import { useUrl } from '@hooks/useUrl';
import ReturnSections from '@pages/ReturnsPage/returnSections/ReturnSections';
import { formatSlotDate, formatSlotTime, type FormValues, parseTimezoneDate } from './utils';
import type { OMS } from 'types';

interface DhlConfirmationPageProps {
    orderReturn: OMS.UserOrderReturn;
}

const DhlConfirmationPage = ({ orderReturn }: DhlConfirmationPageProps) => {
    const { locale } = useAppContext();
    const { routes } = useUrl();

    const { watch } = useFormContext<FormValues>();
    const address = watch('address');
    const returnMode = watch('returnMode');
    const pickupSlot = watch('pickupSlot');
    const timezone = watch('timezone');
    const selectedOrder = watch('order');

    const startDate = pickupSlot?.startTime ? parseTimezoneDate(pickupSlot.startTime, timezone) : undefined;
    const endDate = pickupSlot?.closeTime ? parseTimezoneDate(pickupSlot.closeTime, timezone) : undefined;

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <PageHeader className="mb-12 text-center" title="return.title">
                <FormattedMessage
                    id="return.dhl_confirmation.title"
                    values={{
                        br: <br />,
                        day: startDate ? formatSlotDate(startDate, locale, true) : '',
                        startTime: startDate ? formatSlotTime(startDate, locale) : '',
                        endTime: endDate ? formatSlotTime(endDate, locale) : '',
                    }}
                />
            </PageHeader>

            {address && (
                <div className="text-center">
                    <p className="text-sm">
                        <FormattedMessage id="return.dhl_confirmation.address" />
                    </p>
                    <AddressSimple address={address} className="text-sm font-bold" />
                </div>
            )}

            {returnMode && (
                <ReturnSections
                    orderReference={selectedOrder?.number}
                    countryISO={address?.countryCode}
                    pickupAddress={address}
                    pickupDateStart={pickupSlot?.startTime}
                    pickupDateEnd={pickupSlot?.closeTime}
                    hasReturnLabel={!!selectedOrder?.hasReturnLabel}
                    isDhl
                    isDhlConfirm
                />
            )}

            <div className="mt-24">
                <LinkButton className="w-full" color="secondary" to={`${routes.returns}/detail/${orderReturn.uid}`}>
                    <FormattedMessage id="return.dhl_confirmation.link_return" />
                </LinkButton>
                <LinkButton className="mt-4 w-full" color="secondary" to={'/'}>
                    <FormattedMessage id="return.dhl_confirmation.link_account" />
                </LinkButton>
            </div>
        </>
    );
};

export default DhlConfirmationPage;
