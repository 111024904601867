import { type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import IconEmail from '@components/Icons/IconEmail';
import IconInstagram from '@components/Icons/IconInstagram';
import IconWhatsapp from '@components/Icons/IconWhatsapp';
import { useAppContext } from '@context/AppContext';
import { BRANDS } from '@utils/brand';
import { useUrl } from './useUrl';

export const contactIconMessages = defineMessages({
    email: { id: 'contact.links.email.icon_label' },
    instagram: { id: 'contact.links.instagram.icon_label' },
    whatsapp: { id: 'contact.links.whatsapp.icon_label' },
});

const contactUrlMessagesSezane = defineMessages({
    au: { id: 'contact.links.url.sezane.au' },
    ca: { id: 'contact.links.url.sezane.ca' },
    us: { id: 'contact.links.url.sezane.us' },
    default: { id: 'contact.links.url.sezane.en' },
});
const contactUrlMessagesOctobre = defineMessages({
    au: { id: 'contact.links.url.octobre.au' },
    ca: { id: 'contact.links.url.octobre.ca' },
    us: { id: 'contact.links.url.octobre.us' },
    default: { id: 'contact.links.url.octobre.en' },
});

interface ContactData {
    id: 'whatsapp' | 'email' | 'instagram';
    icon: ReactNode;
    link: string;
}

export const useContactData = () => {
    const { brandCode, site } = useAppContext();
    const { urls } = useUrl();
    const intl = useIntl();
    const whatsAppPhone = brandCode === BRANDS.sezane ? '07.87.17.34.58' : '06.77.82.64.83';
    const formattedWhatsAppPhone = whatsAppPhone.slice(1).replace(/\./g, '');
    const instagramId = brandCode === BRANDS.sezane ? 'sezane' : 'octobre_editions';

    const siteContactUrlMessages = brandCode === BRANDS.sezane ? contactUrlMessagesSezane : contactUrlMessagesOctobre;
    const contactUrl = ['AU', 'CA', 'JP', 'US'].includes(site.code?.toUpperCase() || '')
        ? intl.formatMessage(siteContactUrlMessages[site.code!] || siteContactUrlMessages.default)
        : urls.contact;

    const contactData: ContactData[] = [
        {
            id: 'whatsapp',
            icon: <IconWhatsapp className="mx-auto mb-5 h-12" />,
            link: `https://api.whatsapp.com/send?phone=33${formattedWhatsAppPhone}`,
        },
        {
            id: 'email',
            icon: <IconEmail className="mx-auto mb-5 h-12" />,
            link: contactUrl,
        },
        {
            id: 'instagram',
            icon: <IconInstagram className="mx-auto mb-5 h-12" />,
            link: `https://www.instagram.com/${instagramId}/`,
        },
    ];

    return contactData;
};
